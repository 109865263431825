/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { css } from '@emotion/react';

export default class Loader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		let loader = css({
			position: 'absolute',
			display: 'block',
			width: '100%',
			height: '100%',
			zIndex: '1000',
			margin: '0 auto',
			top: '0',
			left: '0',
			borderColor: 'transparent',
			background: 'rgb(255,255,255, .2)',
		});

		let size = this.props.size ? this.props.size : 48;
		let color = this.props.color ? this.props.color : '#024F88';
		const parents = css({
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		});
		const spinner = css({
			color: color,
			width: size,
			height: size,
		});
		return (
			<div css={loader}>
				<div css={parents}>
					<Spinner animation='border' css={spinner} />
				</div>
			</div>
		);
	}
}
